<template>
    <div style="width: 200px">
        <monaco-editor-control v-model="code" />
    </div>
</template>

<script>
import MonacoEditorControl from "@Platon/components/form/controls/MonacoEditorControl"

export default {
    components: {
        MonacoEditorControl
    },
    data() {
        return {
            code: '{"code":"let test = 123","test":"","es5":"return(function(){})();"}',
            code2: "return user",
            code3: {
                type: "json",
                value: '{"code":"let test = 123","test":"","es5":"return(function(){})();"}'
            },
            code4: {
                code: "return user",
                test: "",
                es5: ""
            },
            code5: "",
            code6: null
        }
    },
    watch: {
        code(val) {
            console.log(JSON.parse(val))
        }
    }
}
</script>
